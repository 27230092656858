import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './Menu.module.scss';

interface Props {
  children: React.ReactNode;
  onStateChange?: (isOpen: boolean) => void;
  className?: string;
  isLocked?: boolean;
  isOpen?: boolean;
  isKeepOpen?: boolean;
}

interface MenuChildProps extends Props {
  menuId?: number;
  isMenuOpen?: boolean;
  setMenuOpen?: (open: boolean) => void;
}

// doesn't matter what this value is, so long as it isn't duplicated across component instances on the same page
let currentId = 1;

const Menu = (
  {
    children,
    className,
    isLocked = false,
    onStateChange = () => null,
    isOpen: controlledIsOpen,
    isKeepOpen = false,
  }: Props,
  ref: React.ForwardedRef<any>
) => {
  const [id] = useState(currentId++);
  const [isOpen, setIsOpen] = useState(
    (isKeepOpen || controlledIsOpen) ?? false
  );

  useEffect(() => {
    if (isKeepOpen) {
      setIsOpen(true);
    } else if (controlledIsOpen !== undefined) {
      setIsOpen(controlledIsOpen);
    }
  }, [isKeepOpen, controlledIsOpen]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (
      React.isValidElement<MenuChildProps>(child) &&
      typeof child.type !== 'string'
    ) {
      return React.cloneElement(child, {
        menuId: id,
        isMenuOpen: isOpen,
        setMenuOpen: (open: boolean) => {
          if (!isKeepOpen) {
            setIsOpen(isLocked ? false : open);
            onStateChange(isLocked ? false : open);
          }
        },
      });
    }
    return child;
  });
  const menuClassNames = [styles.Menu];
  if (className) menuClassNames.push(className);

  return (
    <div ref={ref} className={classNames(menuClassNames)}>
      {childrenWithProps}
    </div>
  );
};

const forwardedMenu = React.forwardRef<any, Props>(Menu);
export default forwardedMenu;
export { forwardedMenu as Menu };
