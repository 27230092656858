import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { Toast, ToastContainer, createToast } from '../../../components/core';
import { captureApolloError } from '../../../util/apolloError';
import TitleAndAbstract from './TitleAndAbstract';
import FullTextReview from './FullTextReview';
import Extraction from './Extraction';
import ImportStudiesNotification from './ImportStudiesNotification';
import ImportReferencesSummary from './ImportReferencesSummary';
import ReviewLinkContext from './ReviewLinkContext';
import { ReviewFeaturesProvider } from 'contexts/ReviewFeaturesProvider';
import Spinner from 'system/elements/Spinner';
import { QueryProvider } from 'query/QueryProvider';
import { Stack } from 'components/layout';

export type Stage = 'screen' | 'select' | 'included';

export interface Permissions {
  consensusRequired: boolean;
}

export interface Statistics {
  contribution: number;
  complete: number;
  partiallyComplete: number;
  available: number;
  notStarted: number;
  consensusRequired: number;
}

interface ReviewHomeProps {
  importsLink: string;
  newImportLink: string;
  reviewId: string;
  reviewLink: string;
  permissions: Permissions;
  reviewerName: string;
  stage: 'screen' | 'select' | 'included';
  isAutoExclusionEnabled: boolean;
  isAutoExclusionSupported: boolean;
}

const GET_COUNT_QUERY = gql`
  query getCounts($reviewId: ID!) {
    review: node(id: $reviewId) {
      ... on Review {
        categoryCounts {
          screen
          select
          included
          excluded
          irrelevant
          excluded
        }
        statistics {
          screen {
            ...statsFragment
          }
          select {
            ...statsFragment
          }
          included {
            ...statsFragment
          }
        }
      }
    }
  }

  fragment statsFragment on DetailedStatistics {
    complete
    partiallyComplete
    consensusRequired
    available
    notStarted
    contribution
  }
`;

const Home = ({
  reviewId,
  reviewLink,
  reviewerName,
  importsLink,
  newImportLink,
  isAutoExclusionEnabled,
  isAutoExclusionSupported,
}: ReviewHomeProps) => {
  const { data, error, loading } = useQuery(GET_COUNT_QUERY, {
    variables: { reviewId },
    errorPolicy: 'all',
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    captureApolloError(error);
    createToast(
      <Toast type="danger">
        {'Whoops! There was a technical error and some of your review statistics may be missing. ' +
          'Please contact Covidence Support if this persists.'}
      </Toast>
    );
  }

  const categoryCounts = data?.review?.categoryCounts;
  const irrelevant = categoryCounts?.irrelevant;
  const screen = categoryCounts?.screen;
  const excluded = categoryCounts?.excluded;
  const select = categoryCounts?.select;
  const included = categoryCounts?.included;
  const statistics = data?.review?.statistics;

  const firstOpenView = () => {
    if (screen > 0) {
      return 'title_and_abstract';
    }

    if (select > 0) {
      return 'full_text_review';
    }

    if (included > 0) {
      return 'extraction';
    }

    return 'import_summary';
  };

  return (
    <QueryProvider>
      <ReviewLinkContext.Provider value={reviewLink}>
        <ReviewFeaturesProvider>
          <Stack spacing={5}>
            <ImportStudiesNotification reviewId={reviewId} />

            <ImportReferencesSummary
              historyLink={importsLink}
              newImportLink={newImportLink}
              isOpen={firstOpenView() === 'import_summary'}
              reviewId={reviewId}
              isAutoExclusionEnabled={isAutoExclusionEnabled}
              isAutoExclusionSupported={isAutoExclusionSupported}
            />

            <TitleAndAbstract
              isOpen={firstOpenView() === 'title_and_abstract'}
              reviewerName={reviewerName}
              irrelevant={irrelevant}
              stats={statistics?.screen}
            />

            <FullTextReview
              isOpen={firstOpenView() === 'full_text_review'}
              reviewerName={reviewerName}
              excluded={excluded}
              stats={statistics?.select}
            />

            <Extraction
              isOpen={firstOpenView() === 'extraction'}
              reviewerName={reviewerName}
              complete={statistics?.included?.complete}
              stats={statistics?.included}
            />
          </Stack>
        </ReviewFeaturesProvider>
      </ReviewLinkContext.Provider>
      <ToastContainer />
    </QueryProvider>
  );
};

export default Home;
