import React, { useEffect, useState, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { formatDistanceToNow } from 'date-fns';
import OptionToRevisitStudiesModal from '../OptionToRevisitStudiesModal';
import {
  UnchangedDefaultsWarningModal,
  showUnchangedDefaultWarning,
} from './components/Modals/UnchangedDefaultsWarningModal';
import InterventionsPanel from './components/InterventionsPanel/InterventionsPanel';
import PopulationPanel from './components/PopulationPanel/PopulationPanel';
import OutcomesPanel from './components/OutcomesPanel/OutcomesPanel';
import IdentificationPanel from './components/IdentificationPanel/IdentificationPanel';
import MethodsPanel from './components/MethodsPanel/MethodsPanel';
import ResultsDataPanel from './components/ResultsDataPanel/ResultsDataPanel';
import styles from './DataExtractionTemplate.module.scss';
import HelpPanel from './components/HelpPanel/HelpPanel';
import { useConfirmUnsaved } from 'hooks/useConfirmUnsaved';
import { DataExtractionTemplate as DataExtractionTemplateType } from 'types/DataExtraction';
import {
  getDETemplate,
  updateDETemplate,
  createEmptyDETemplate,
  getReviewId,
  getHttpErrorMessage,
  UpdateResponseBody,
} from 'query/review';
import { EXTRACTION_STUDIES_LIST_ROUTE, tokenizeRoute } from 'query/routes';
import { IsolationPage, AppFooter } from 'components/shared';
import {
  Button,
  Icon,
  IconList,
  Link,
  Text,
  createToast,
  Toast,
  ToastButton,
  ToastContainer,
} from 'components/core';

// copy this here so we can add to it if needed
export type DataExtractionTemplateState = DataExtractionTemplateType;

type Props = {
  getTemplate?: () => Promise<DataExtractionTemplateType>;
  updateTemplate?: (
    template: DataExtractionTemplateType,
    revisitStudies: boolean
  ) => Promise<{ status: number; body: UpdateResponseBody }>;
};

const backRoute = tokenizeRoute(EXTRACTION_STUDIES_LIST_ROUTE, {
  review_id: getReviewId(),
});

const DataExtractionTemplate = ({
  getTemplate = getDETemplate,
  updateTemplate = updateDETemplate,
}: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState<React.ReactElement | null>();
  const revisitStudies = useRef(false);
  const formFn = useForm<DataExtractionTemplateState>({
    mode: 'all',
    defaultValues: createEmptyDETemplate(),
    shouldFocusError: false,
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty, isValid },
  } = formFn;

  const [clean, dirty] = useConfirmUnsaved();
  useEffect(() => {
    isDirty ? dirty() : clean();
  }, [isDirty, dirty, clean]);

  const [template, setTemplate] = useState<DataExtractionTemplateType>();
  useEffect(() => {
    getTemplate().then((template) => {
      setTemplate(template);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(template);
  }, [reset, template]);

  const shouldShowRevisitModal = (): boolean => {
    return (
      (template?.number_of_revisitable_studies || 0) > 0 && isValid && isDirty
    );
  };

  const onPublish = (revisit = false) => {
    revisitStudies.current = revisit;
    publishTemplate();
  };

  const publishTemplate = handleSubmit(
    (template) => {
      setLoading(true);
      const revisiting = revisitStudies.current;
      updateTemplate(template, revisiting)
        .then(({ status, body }) => {
          if (status === 200) {
            clean();
            window.location.href = backRoute.toString();
            document.cookie =
              'de_template_published=true; SameSite=Lax; Path=/';
            if (revisiting) {
              document.cookie = 'de_moved_back=true; SameSite=Lax; Path=/';
            }
            return;
          }

          const errorMessages = body.errors || [getHttpErrorMessage(status)];

          errorMessages.forEach((message) => {
            createToast(
              <Toast type="danger">
                {message}
                <ToastButton
                  to={'mailto:support@covidence.org'}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  Need help?
                </ToastButton>
              </Toast>,
              { duration: 6000 }
            );
          });
        })
        .finally(() => setLoading(false));
    },
    () => {
      const invalidInput = document.querySelector(
        '[aria-invalid=true]'
      ) as HTMLElement;
      invalidInput?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setTimeout(() => invalidInput?.focus(), 1000);
    }
  );

  const showOptionToRevisitModal = () => {
    setModal(
      <OptionToRevisitStudiesModal
        numberOfRevisitableStudies={
          template?.number_of_revisitable_studies || 0
        }
        onClose={() => setModal(null)}
        onPublish={onPublish}
        templateType="DataExtraction"
      />
    );
  };

  const showUnchangedDefaultsWarningModal = () => {
    setModal(
      <UnchangedDefaultsWarningModal
        onClose={() => setModal(null)}
        onPublish={onPublish}
      />
    );
  };

  const handlePublish = () => {
    if (shouldShowRevisitModal()) {
      showOptionToRevisitModal();
    } else if (showUnchangedDefaultWarning(formFn)) {
      showUnchangedDefaultsWarningModal();
    } else {
      publishTemplate();
    }
  };

  const resultsDataAnchor = 'resultsdata';

  return (
    <FormProvider {...formFn}>
      {modal}
      <IsolationPage
        back={backRoute}
        title="Data extraction template"
        controls={
          <>
            {template?.updated_at && (
              <Text variant="weak">
                Published{' '}
                {formatDistanceToNow(Date.parse(template?.updated_at), {
                  addSuffix: true,
                })}{' '}
                by {template?.updated_by?.first_name}
              </Text>
            )}

            <Button
              onClick={handlePublish}
              type="brand"
              isLoading={isLoading}
              data-pendo-key="det-publish"
            >
              Publish template
            </Button>
            <Link
              className={styles.HelpLink}
              onClick={() => ''}
              data-pendo-key="det-help"
              aria-label="Get help"
            >
              <Icon icon={IconList.light.faQuestionCircle} />
            </Link>
          </>
        }
      >
        <HelpPanel />
        <div className={styles.DataExtractionTemplate}>
          <IdentificationPanel
            hasExtractedData={template?.has_extracted_data}
          />
          <MethodsPanel hasExtractedData={template?.has_extracted_data} />
          <PopulationPanel hasExtractedData={template?.has_extracted_data} />
          <InterventionsPanel hasExtractedData={template?.has_extracted_data} />
          <OutcomesPanel resultsDataAnchor={resultsDataAnchor} />
          <ResultsDataPanel resultsDataAnchor={resultsDataAnchor} />
        </div>
        <AppFooter />
      </IsolationPage>
      <ToastContainer />
    </FormProvider>
  );
};

export default DataExtractionTemplate;
