import { gql } from '@apollo/client';
import idx from 'idx';
import React, { useState } from 'react';
import { captureApolloError } from '../../../util/apolloError';
import AssignReviewAdmin from './AssignReviewAdmin';
import AssignedAdminFilterDropdown from './AssignedAdminFilterDropdown';
import { ID } from 'util/types';
import { queryStringToVariablesDefaults } from 'util/queryString';
import AssignedAdministrator from 'components/AssignedAdministrator';
import FormattedDate from 'components/FormattedDate';
import Sidebar from 'components/Sidebar';
import useCollectionQuery from 'hooks/useCollectionQuery';
import useSortVariables from 'hooks/useSortVariables';
import { PrimaryLink } from 'system/base/Link';
import Text from 'system/base/Text';
import { Link } from 'system/base/Link';
import Icon from 'system/base/Icon';
import DataTable, { Columns } from 'system/data/DataTable';
import SortDropdown from 'system/data/SortDropdown';
import LabelFor from 'system/elements/LabelFor';
import SearchInput from 'system/elements/SearchInput';
import DataWithControls from 'system/layout/DataWithControls';
import useSyncVariablesToQueryString from 'hooks/useSyncVariablesToQueryString';
import {
  ReviewSort,
  ReviewsQuery,
  ReviewsQueryVariables,
  SortDirection,
} from 'types/graphql';

const GET_REVIEWS = gql`
  query Reviews(
    $organizationId: ID!
    $after: String
    $filter: OrganizationReviewsFilter
    $sort: ReviewSort
    $sortDirection: SortDirection
  ) {
    organization: node(id: $organizationId) {
      ... on Organization {
        reviews(
          first: 25
          after: $after
          filter: $filter
          sort: $sort
          sortDirection: $sortDirection
        )
          @connection(
            key: "reviews"
            filter: ["filter", "sort", "sortDirection"]
          ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            title
            lastActivityAt
            collaborators {
              total
            }
            assignedAdministrator {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const SORT_OPTIONS = [
  {
    label: 'Review Title (A-Z)',
    value: { sort: ReviewSort.Title, sortDirection: SortDirection.Asc },
  },
  {
    label: 'Review Title (Z-A)',
    value: { sort: ReviewSort.Title, sortDirection: SortDirection.Desc },
  },
  {
    label: 'Most recently active',
    value: {
      sort: ReviewSort.LastActivityAt,
      sortDirection: SortDirection.Desc,
    },
  },
  {
    label: 'Least recently active',
    value: {
      sort: ReviewSort.LastActivityAt,
      sortDirection: SortDirection.Asc,
    },
  },
];

interface Props {
  organizationId: ID;
  query?: {
    assignedAdministrator?: ID | null;
    sort?: ReviewSort;
    sortDirection?: SortDirection;
  };
}

const NewReviewButton = ({
  organizationId,
  className,
}: {
  organizationId: ID;
  className?: string;
}) => (
  <PrimaryLink
    href={`/reviews/new?organization_id=${organizationId}`}
    className={className}
    style={{ marginLeft: 8 }}
  >
    <Icon name="plus-square" />
    Create a new review
  </PrimaryLink>
);

const Reviews = ({ organizationId, query }: Props) => {
  const [isAdminAssignmentOpen, setAdminAssignmentOpen] = useState(false);
  const [adminAssignmentReview, setAdminAssignmentReview] = useState<any>();
  const showAssignableAdmins = (review: any) => {
    setAdminAssignmentReview(review);
    setAdminAssignmentOpen(true);
  };
  const closeAdminAssignment = () => {
    setAdminAssignmentOpen(false);
    setAdminAssignmentReview(null);
  };

  const assignReviewAdmin = adminAssignmentReview && (
    <AssignReviewAdmin
      organizationId={organizationId}
      reviewId={adminAssignmentReview.id}
      close={closeAdminAssignment}
      assignedAdministratorId={
        adminAssignmentReview.assignedAdministrator &&
        adminAssignmentReview.assignedAdministrator.id
      }
    />
  );

  const {
    data,
    variables,
    setVariable,
    setVariables,
    loading,
    error,
    loadMore,
  } = useCollectionQuery<ReviewsQuery, ReviewsQueryVariables>({
    query: GET_REVIEWS,
    variables: queryStringToVariablesDefaults<ReviewsQueryVariables>(
      {
        organizationId,
        sort: ReviewSort.LastActivityAt,
        sortDirection: SortDirection.Desc,
      },
      query || {},
      ['assignedAdministrator']
    ),
    path: ['organization', 'reviews'],
  });

  if (error) captureApolloError(error); // will be rendered by the DataTable

  const [sort, setSort] = useSortVariables(variables, setVariables);

  useSyncVariablesToQueryString(variables, [
    'assignedAdministrator',
    'sort',
    'sortDirection',
  ]);

  const collection = idx(data, (_) => _.organization.reviews.nodes) || [];

  /* eslint-disable react/display-name */
  const columns: Columns<typeof collection> = [
    {
      heading: 'Review title',
      primary: true,
      render: ({ id, title }) => (
        <Text textStyle="truncate" title={title}>
          <Link href={`/reviews/${id}`}>{title}</Link>
        </Text>
      ),
    },
    {
      heading: 'Last active',
      width: 170,
      render: ({ lastActivityAt }) => (
        <FormattedDate
          date={lastActivityAt}
          format="relative"
          options={{ addSuffix: true }}
        />
      ),
    },
    {
      heading: 'Collaborators',
      width: 110,
      render: (review) => (
        <Link href={`/reviews/${review.id}/authors`}>
          {review.collaborators.total}
        </Link>
      ),
    },
    {
      heading: 'Assigned admin',
      width: 145,
      render: (review) => (
        <AssignedAdministrator
          review={review}
          showAssignableAdmins={showAssignableAdmins}
        />
      ),
    },
    {
      key: 'Settings',
      heading: '',
      width: 35,
      render: ({ id }) => (
        <a href={`/reviews/${id}/details`}>
          <Icon name="cog" />
        </a>
      ),
    },
  ];
  /* eslint-enable react/display-name */

  return (
    <>
      <DataWithControls
        filters={
          <>
            <LabelFor
              flexDirection="row-reverse"
              input={
                <AssignedAdminFilterDropdown
                  organizationId={organizationId}
                  variables={variables}
                  setVariable={setVariable}
                  variableKey={['filter', 'assignedAdministrator']}
                />
              }
            >
              Show
            </LabelFor>
            <SortDropdown
              sort={sort}
              setSort={setSort}
              options={SORT_OPTIONS}
            />
          </>
        }
        actions={
          <>
            <SearchInput
              defaultValue={idx(variables, (_) => _.filter.search) || ''}
              onSearch={(value) => setVariable(['filter', 'search'], value)}
              placeholder="Find a review"
            />
            <NewReviewButton organizationId={organizationId} />
          </>
        }
      >
        <DataTable
          collection={collection}
          columns={columns}
          loading={loading}
          error={error}
          loadMore={loadMore}
          empty="There are no reviews in this organisation"
        />
      </DataWithControls>

      <Sidebar
        open={isAdminAssignmentOpen}
        onOpenChange={(open: boolean) => {
          if (!open) closeAdminAssignment();
        }}
      >
        {assignReviewAdmin}
      </Sidebar>
    </>
  );
};

export default Reviews;
