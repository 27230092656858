import { ApolloError } from '@apollo/client';

export function captureApolloError(error: ApolloError) {
  const graphQLErrors = error.graphQLErrors?.filter(Boolean) || [];
  const networkError = error.networkError;

  // Set detailed GraphQL error context
  Sentry.setContext('GraphQL Errors', {
    count: graphQLErrors.length,
    errors: graphQLErrors.map((err) => ({
      message: err.message,
      path: err.path,
      extensions: err.extensions,
    })),
  });

  // Set network error context if present
  if (networkError) {
    Sentry.setContext('Network Error', {
      message: networkError.message,
      name: networkError.name,
      stack: networkError.stack,
    });
  }
  Sentry?.captureException(error);
}
