import React, { HTMLAttributes, useMemo } from 'react';
import { v4 as UUID } from 'uuid';
import { FormLabel } from '..';
import styles from '../Form.module.scss';
import {
  Input,
  Select,
  Text,
  TextArea,
  Checkbox,
  RadioButton,
  ComboBox,
} from 'components/core';

interface Props extends HTMLAttributes<HTMLDivElement> {
  error?: string | JSX.Element;
}

const isFormInput = (child: React.ReactElement<any>): boolean => {
  return (
    child.type === Input ||
    child.type === Select ||
    child.type === TextArea ||
    child.type === Checkbox ||
    child.type === RadioButton ||
    child.type === ComboBox
  );
};

const FormError = ({
  error,
  id,
}: {
  id: string;
  error?: string | React.ReactElement<typeof Text>;
}) => {
  if (!error) {
    return null;
  }

  if (React.isValidElement(error)) {
    return <div id={id}>{error}</div>;
  }

  return (
    <Text id={id} variant="danger" size="xs">
      {error}
    </Text>
  );
};

const FormControl = ({ className, children, error, ...props }: Props) => {
  const sharedState = useMemo(() => ({ id: UUID(), errorId: UUID() }), []);
  const classes = [styles.FormControl];

  if (className) classes.push(className);

  // Loop through the children to find an id, if one is present
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && isFormInput(child)) {
      if (child.props.id) sharedState.id = child.props.id;
    }
  });

  const childrenWithProps = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) return child;
    if (isFormInput(child)) {
      const inputProps: Record<string, unknown> = {
        id: child.props.id || sharedState.id,
        isError: !!error || child.props.isError,
      };

      // When an error occurs link to the error message
      if (error) {
        inputProps['aria-errormessage'] = sharedState.errorId;
      }

      return React.cloneElement(child, inputProps);
    }

    if (child.type === FormLabel) {
      return React.cloneElement(child, {
        htmlFor: child.props.htmlFor || sharedState.id,
      } as React.HTMLAttributes<HTMLLabelElement>);
    }

    return child;
  });

  return (
    <div className={classes.join(' ')} {...props}>
      {childrenWithProps}
      <FormError id={sharedState.errorId} error={error} />
    </div>
  );
};

export default FormControl;
