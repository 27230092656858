import React from 'react';
import { Box } from 'theme-ui';
import { gql, useMutation, useQuery } from '@apollo/client';
import { captureApolloError } from '../../util/apolloError';
import { ID } from 'util/types';
import { Review, ReviewTagsQuery, Tag } from 'types/graphql';

import Spinner from 'system/elements/Spinner';
import ErrorMessage from 'components/ErrorMessage';
import DataList from 'components/DataList';

interface ManageTagsProps {
  reviewId: ID;
}

const REVIEW_TAGS_QUERY = gql`
  query ReviewTags($reviewId: ID!) {
    review: node(id: $reviewId) {
      __typename
      ... on Node {
        id
      }
      ... on Review {
        tags {
          id
          isDeleteable
          name
        }
      }
    }
  }
`;

const CREATE_TAG_MUTATION = gql`
  mutation createTag($reviewId: ID!, $name: String!) {
    createTag(input: { reviewId: $reviewId, name: $name }) {
      success
      tag {
        id
        name
        isDeleteable
      }
    }
  }
`;

const DELETE_TAG_MUTATION = gql`
  mutation deleteTag($id: ID!) {
    deleteTag(input: { id: $id }) {
      success
    }
  }
`;

const ManageTags = ({ reviewId }: ManageTagsProps): JSX.Element => {
  const { data, error, loading } = useQuery<ReviewTagsQuery>(
    REVIEW_TAGS_QUERY,
    {
      variables: { reviewId },
    }
  );

  const [deleteTagMutation] = useMutation(DELETE_TAG_MUTATION);
  const [createTagMutation] = useMutation(CREATE_TAG_MUTATION);

  const deleteTag = ({ id }: { id: ID }, callback: () => void) => {
    deleteTagMutation({
      variables: { id },
      refetchQueries: ['ReviewTags'],
    }).then(() => callback());
  };

  const createTag = (name: string, callback: () => void) => {
    createTagMutation({
      variables: { name, reviewId },
      refetchQueries: ['ReviewTags'],
    }).then(() => callback());
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    captureApolloError(error);
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (!data) {
    return (
      <ErrorMessage>
        No data was returned. Please try again and contact Covidence support if
        this error persists.
      </ErrorMessage>
    );
  }

  if (data) {
    const tags = (data.review as Review).tags;
    return (
      <Box mx={'128px'}>
        <DataList
          currentItemsLabel="Existing tags"
          currentItems={tags}
          newItemLabel="Add tag"
          onAddItem={createTag}
          onDeleteItem={deleteTag}
          renderItem={(item: Tag) => item.name}
        />
      </Box>
    );
  }

  return <></>;
};

export default ManageTags;
