import React, { useState } from 'react';

import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import ExtractionToolChangeConfirmationDialog from './ExtractionToolChangeConfirmationDialog';
import styles from './ExtractionToolSelector.module.scss';
import UnableToSwitchModal from './components/UnableToSwitchModal';
import DualExtractorModal from './components/DualExtractorModal';
import SingleExtractorModal from './components/SingleExtractorModal';
import { getReviewId } from 'query/review';
import {
  Text,
  FormLabel,
  Link,
  Icon,
  IconList,
  Badge,
  RadioButton,
  ToastContainer,
} from 'components/core';

export const PENDO_REVIEW_SETTING_PREFIX = 'covidence.review.setting.';

const featurePanelClassNames = (selected: boolean) => {
  const classes = [styles.featurePanel];
  if (selected) {
    classes.push(styles.selected);
  }
  return classNames(classes);
};

interface featurePanelProps {
  selected: boolean;
  onClick: () => void;
  formValue: string;
  name: string;
  summary: string;
  showFeatureComparison: boolean;
  showBadge: boolean;
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
}

const FeaturePanel = ({
  children,
  selected,
  onClick,
  formValue,
  name,
  summary,
  showFeatureComparison,
  showBadge,
}: featurePanelProps) => {
  return (
    <div className={featurePanelClassNames(selected)}>
      <div className={styles.selectionHeader}>
        <RadioButton
          name="extraction"
          size="sm"
          value={formValue}
          selectedValue={selected ? formValue : ''}
          onClick={() => {
            if (!selected) {
              onClick();
            }
          }}
        >
          {name}
        </RadioButton>
        {showBadge && (
          <Badge variant="solid" type="primary" className={styles.featureBadge}>
            Recommended by Cochrane
          </Badge>
        )}
      </div>
      <Text>{summary}</Text>

      <AnimatePresence initial={false}>
        {showFeatureComparison && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ type: 'ease-in-out' }}
          >
            <hr />
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

interface featureProps {
  title: string;
  available: boolean;
  badge?: string;
}

const FeaturePoint = ({ title, available, badge }: featureProps) => {
  const cssClasses = [styles.featurePoint];
  if (!available) {
    cssClasses.push(styles.unavailable);
  }

  return (
    <div className={classNames(cssClasses)}>
      {available ? (
        <Icon
          className={styles.availableIcon}
          icon={IconList.solid.faCircleCheck}
          aria-label="available"
        />
      ) : (
        <Icon
          className={styles.unavailableIcon}
          icon={IconList.light.faCircleXmark}
          aria-label="unavailable"
        />
      )}
      {title}
      {available && badge && (
        <Badge variant="solid" type="primary" className={styles.badge}>
          {badge}
        </Badge>
      )}
    </div>
  );
};

const featureTitles = [
  'Structured format for organised data collection',
  'Unstructured format for flexible data collection',
  'Supports dual reviewer extractions',
  'Supports single reviewer extractions',
  'Automated extraction suggestions',
  'Exports to CSV',
  'Exports to Excel',
  'Exports to RevMan',
];
interface props {
  useExtractionTwo: boolean;
  requireDualExtraction: boolean;
  de1UsersRequired: number;
  de2UsersRequired: number;
  useNewTemplates: boolean;
}

export const ExtractionToolSelector = ({
  useExtractionTwo,
  requireDualExtraction,
  de1UsersRequired,
  de2UsersRequired,
  useNewTemplates,
}: props) => {
  const [modal, setModal] = useState<React.ReactElement | null>();
  const [selectExtractionTwo, setSelectExtractionTwo] = useState(
    useExtractionTwo
  );
  const [showFeatureComparison, setShowFeatureComparison] = useState(true);
  const reviewId = getReviewId();

  const changeExtractionTool = (useExtractionTwo: boolean) => {
    setSelectExtractionTwo(useExtractionTwo);
    setModal(
      <ExtractionToolChangeConfirmationDialog
        title={
          useExtractionTwo ? 'Change to Extraction 2' : 'Change to Extraction 1'
        }
        onConfirm={() => {
          setModal(null);
        }}
        onCancel={() => {
          setModal(null);
          setSelectExtractionTwo(!useExtractionTwo);
        }}
      />
    );
  };

  const onStructuredExtractionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const onClose = () => {
      event.target.value = '2';
      setModal(null);
    };

    const MODAL = {
      COMING_SOON: (
        <UnableToSwitchModal onConfirm={onClose} onClose={onClose} />
      ),
      SINGLE_EXTRACTOR: (
        <SingleExtractorModal
          onConfirm={() => {
            event.target.value = '1';
            setModal(null);
          }}
          onClose={() => {
            event.target.value = '2';
            setModal(null);
          }}
        />
      ),
      DUAL_EXTRACTOR: (
        <DualExtractorModal
          onConfirm={() => {
            event.target.value = '2';
            setModal(null);
          }}
          onClose={() => {
            event.target.value = '1';
            setModal(null);
          }}
        />
      ),
    };

    const singleExtractorWorkflow =
      !requireDualExtraction && event.target.value === '1';

    const dualExtractorWorkflow =
      !requireDualExtraction && event.target.value === '2';

    if (singleExtractorWorkflow) {
      setModal(MODAL.SINGLE_EXTRACTOR);
      return;
    }

    if (dualExtractorWorkflow) {
      setModal(MODAL.DUAL_EXTRACTOR);
      return;
    }

    // NOTE: Pendo doesn't support data-pendo-key on select
    // options so manually send an event
    window?.pendo?.track(
      PENDO_REVIEW_SETTING_PREFIX + 'single_extractor_select_option',
      {
        reviewId: reviewId,
      }
    );

    setModal(MODAL.COMING_SOON);
  };

  const isSingleExtractorWorkflowAvailable = !requireDualExtraction;

  return (
    <>
      {selectExtractionTwo && (
        <div className="input form-group select optional review_extraction_users_required">
          <label
            className="control-label select optional control-label"
            htmlFor="review_extraction_users_required"
          >
            Reviewers required for data extraction
          </label>
          <select
            className="form-control select optional"
            name="review[extraction_users_required]"
            id="review_extraction_users_required"
            defaultValue={de2UsersRequired == 1 ? '1' : '2'}
          >
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
        </div>
      )}
      {!selectExtractionTwo && (
        // DE1
        <div className="input form-group select optional review_extraction_users_required">
          <label
            className="control-label select optional control-label"
            htmlFor="review_structured_extraction_users_required"
          >
            Reviewers required for data extraction
          </label>
          <select
            className="form-control select optional"
            name="review[review_setting_attributes][structured_extraction_users_required]"
            id="review_structured_extraction_users_required"
            disabled={!useNewTemplates}
            defaultValue={de1UsersRequired == 1 ? '1' : '2'}
            onChange={(event) => onStructuredExtractionChange(event)}
          >
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
        </div>
      )}
      <div className={styles.linkGroup}>
        <FormLabel className={styles.comparisonHelpLink}>
          Choose your{' '}
          <Link
            to={
              'https://support.covidence.org/help/which-version-of-data-extraction-should-i-use'
            }
            external
            variant="primary"
          >
            extraction tool
          </Link>
        </FormLabel>
        {showFeatureComparison && (
          <Link
            className={styles.comparisonToggleLink}
            variant="primary"
            onClick={() => setShowFeatureComparison(false)}
          >
            Hide feature comparison <Icon icon={IconList.light.faAngleUp} />
          </Link>
        )}
        {!showFeatureComparison && (
          <Link
            className={styles.comparisonToggleLink}
            variant="primary"
            onClick={() => setShowFeatureComparison(true)}
          >
            Show feature comparison <Icon icon={IconList.light.faAngleDown} />
          </Link>
        )}
      </div>
      {modal}
      <div className={styles.comparisonTable}>
        <FeaturePanel
          selected={!selectExtractionTwo}
          onClick={() => {
            changeExtractionTool(false);
            return false;
          }}
          formValue="extraction_one"
          name="Extraction 1"
          summary="Ideal for intervention reviews with a standardised PICO(T) structure for ease of meta-analysis"
          showFeatureComparison={showFeatureComparison}
          showBadge={true}
        >
          <div className={styles.featuresList}>
            <FeaturePoint available={true} title={featureTitles[0]} />
            <FeaturePoint available={false} title={featureTitles[1]} />
            <FeaturePoint available={true} title={featureTitles[2]} />
            <FeaturePoint
              available={isSingleExtractorWorkflowAvailable}
              title={featureTitles[3]}
              badge="Early access"
            />
            <FeaturePoint available={true} title={featureTitles[4]} />
            <FeaturePoint available={true} title={featureTitles[5]} />
            <FeaturePoint available={true} title={featureTitles[6]} />
            <FeaturePoint available={true} title={featureTitles[7]} />
          </div>
        </FeaturePanel>
        <FeaturePanel
          selected={selectExtractionTwo}
          onClick={() => {
            changeExtractionTool(true);
            return false;
          }}
          formValue="extraction_two"
          name="Extraction 2"
          summary="Best suited for non-intervention reviews with a customisable structure"
          showFeatureComparison={showFeatureComparison}
          showBadge={false}
        >
          <div className={styles.featuresList}>
            <FeaturePoint available={false} title={featureTitles[0]} />
            <FeaturePoint available={true} title={featureTitles[1]} />
            <FeaturePoint available={true} title={featureTitles[2]} />
            <FeaturePoint available={true} title={featureTitles[3]} />
            <FeaturePoint available={false} title={featureTitles[4]} />
            <FeaturePoint available={true} title={featureTitles[5]} />
            <FeaturePoint available={false} title={featureTitles[6]} />
            <FeaturePoint available={false} title={featureTitles[7]} />
          </div>
        </FeaturePanel>
      </div>
      <ToastContainer />
    </>
  );
};
