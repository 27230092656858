import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import GET_ADMINISTRATORS from '../getAdministrators';
import { captureApolloError } from '../../../../util/apolloError';
import AdminList from './AdminList';
import {
  AdministratorsQuery,
  AdministratorsQueryVariables,
  AdministratorFragment,
} from 'types/graphql';
import LoadingSpinner from 'system/elements/Spinner';
import ErrorMessage from 'components/ErrorMessage';
import { ID } from 'util/types';

const ASSIGN_ADMIN = gql`
  mutation assignAdmin($reviewId: ID!, $administratorId: ID!) {
    assign: assignOrganizationAdministratorToReview(
      input: { reviewId: $reviewId, administratorId: $administratorId }
    ) {
      success
      review {
        id
        assignedAdministrator {
          id
          name
          email: primaryEmail
        }
      }
      errors {
        message
      }
    }
  }
`;

const UNASSIGN_ADMIN = gql`
  mutation unassignAdmin($reviewId: ID!) {
    assign: unassignOrganizationAdministratorFromReview(
      input: { reviewId: $reviewId }
    ) {
      success
      review {
        id
        assignedAdministrator {
          id
          name
          email: primaryEmail
        }
      }
      errors {
        message
      }
    }
  }
`;

interface Props {
  organizationId: ID;
  reviewId: ID;
  assignedAdministratorId?: ID;
  close: () => void;
}

const AssignReviewAdmin = ({
  organizationId,
  reviewId,
  assignedAdministratorId,
  close,
}: Props) => {
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [assignAdmin] = useMutation(ASSIGN_ADMIN);
  const [unassignAdmin] = useMutation(UNASSIGN_ADMIN);

  const selectAdmin = (administrator: AdministratorFragment | null) => {
    setSaving(true);
    let mutation;
    if (administrator) {
      mutation = () =>
        assignAdmin({
          variables: { reviewId, administratorId: administrator.id },
        });
    } else {
      mutation = () => unassignAdmin({ variables: { reviewId } });
    }

    mutation()
      .then((result: any) => {
        setSaving(false);
        if (result.data.assign.success) {
          close();
        } else {
          setErrorMessage(
            result.data.assign.errors.map((e: any) => `${e.message}`).join(',')
          );
        }
      })
      .catch((error) => {
        setSaving(false);
        setErrorMessage(error.message);
      });
  };

  let status;
  if (saving) {
    status = <LoadingSpinner />;
  } else if (errorMessage) {
    status = <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  const { data, error, loading } = useQuery<
    AdministratorsQuery,
    AdministratorsQueryVariables
  >(GET_ADMINISTRATORS, {
    variables: { organizationId },
  });

  let adminList;
  if (loading) {
    adminList = <LoadingSpinner />;
  } else if (error) {
    captureApolloError(error);
    adminList = <ErrorMessage>{error.message}</ErrorMessage>;
  } else {
    adminList = (
      <AdminList
        administrators={
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          data!.organization!.administrators
            .nodes as readonly AdministratorFragment[]
        }
        selected={assignedAdministratorId}
        selectAdmin={selectAdmin}
        disabled={saving}
      />
    );
  }

  return (
    <>
      <h2 className="tcn pa3 ma0 bb b--light-gray">Assign Administrator</h2>
      {adminList}
      {status}
    </>
  );
};

export default AssignReviewAdmin;
