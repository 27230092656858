import React, { ChangeEvent, memo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styles from '../../DataExtractionTemplate.module.scss';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import EditableField from '../EditableField';
import {
  validate,
  NO_EQUIVALENT_VALUE,
} from '../../../components/editableFieldValidation';
import ConfirmationDialog from '../ConfirmationDialog';
import SectionTitle from '../SectionTitle';
import {
  Heading,
  Text,
  Icon,
  IconList,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  FormControl,
  Checkbox,
} from 'components/core';
import {
  HiddenFieldCategory,
  TemplateHiddenField,
  TemplateHiddenFields,
} from 'types/DataExtraction';

const RESERVED_FIELD_NAMES: Array<string> = [
  'sponsorship source',
  'country',
  'setting',
  'comments',
  "author's name",
  'institution',
  'email',
  'address',
];

interface Props {
  hasExtractedData?: boolean;
}

const IdentificationPanel = ({ hasExtractedData = false }: Props) => {
  const [modal, setModal] = useState<React.ReactElement | null>();
  const { getValues, setValue, watch } = useFormContext();
  const hiddenFields = (watch('hidden_fields') as TemplateHiddenFields) || [];
  const additionalFields = useFieldArray<DataExtractionTemplateState>({
    name: 'identification.additionalFields',
  });
  const MAX_LENGTH = 255;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;

    const setCheckedFields = (fields: TemplateHiddenFields) =>
      !checked
        ? [
            ...fields,
            { category: HiddenFieldCategory.IDENTIFICATION, name: value },
          ]
        : fields?.filter((f) => f.name !== value);

    setValue('hidden_fields', setCheckedFields(hiddenFields));
  };

  const isChecked = (field: string) => {
    return !hiddenFields.some((f: TemplateHiddenField) => f.name === field);
  };

  return (
    <section className={styles.section}>
      {modal}
      <SectionTitle
        title="Identification"
        pendoKey="det-identification"
        ariaLabel="Get help with identification"
      />
      <Popover closeOnBlur={true} renderCloseButton={false}>
        <Text className={styles.PopoverTriggerText}>
          Use this section to define relevant{' '}
          <PopoverTrigger>
            <Text component="span" className={styles.PopoverTrigger}>
              identifying information
            </Text>
          </PopoverTrigger>{' '}
          you need to collect from studies.
        </Text>
        <PopoverContent className={styles.PopoverContentText}>
          <Text>
            This includes details about the study as well as the author’s
            contact details.
          </Text>
          <Text>
            Where possible we will suggest values for these fields when
            extracting to save time.
          </Text>
          <Text>
            If you don’t need to extract a field, you can de-select it from the
            list below.
          </Text>
        </PopoverContent>
      </Popover>

      <div className={styles.details}>
        <Heading type="h5" component="h4" variant="neutral">
          Study details
        </Heading>
        <ul>
          <li>
            <FormControl>
              <Checkbox
                name="sponsorship_source"
                value="sponsorship_source"
                checked={isChecked('sponsorship_source')}
                onChange={handleChange}
              >
                <Text variant="medium">Sponsorship source</Text>
              </Checkbox>
            </FormControl>
          </li>
          <li>
            <FormControl>
              <Checkbox
                name="country"
                value="country"
                checked={isChecked('country')}
                onChange={handleChange}
              >
                <Text variant="medium">Country</Text>
              </Checkbox>
            </FormControl>
          </li>
          <li>
            <FormControl>
              <Checkbox
                name="setting"
                value="setting"
                checked={isChecked('setting')}
                onChange={handleChange}
              >
                <Text variant="medium">Setting</Text>
              </Checkbox>
            </FormControl>
          </li>
          <li>
            <FormControl>
              <Checkbox
                name="comments"
                value="comments"
                checked={isChecked('comments')}
                onChange={handleChange}
              >
                <Text variant="medium">Comments</Text>
              </Checkbox>
            </FormControl>
          </li>
        </ul>
      </div>

      <div className={styles.details}>
        <Heading type="h5" component="h4" variant="neutral">
          {`Author's contact details`}
        </Heading>
        <ul>
          <li>
            <FormControl>
              <Checkbox
                name="name"
                value="name"
                checked={isChecked('name')}
                onChange={handleChange}
              >
                <Text variant="medium">Name</Text>
              </Checkbox>
            </FormControl>
          </li>
          <li>
            <FormControl>
              <Checkbox
                name="institution"
                value="institution"
                checked={isChecked('institution')}
                onChange={handleChange}
              >
                <Text variant="medium">Institution</Text>
              </Checkbox>
            </FormControl>
          </li>
          <li>
            <FormControl>
              <Checkbox
                name="email"
                value="email"
                checked={isChecked('email')}
                onChange={handleChange}
              >
                <Text variant="medium">Email</Text>
              </Checkbox>
            </FormControl>
          </li>
          <li>
            <FormControl>
              <Checkbox
                name="address"
                value="address"
                checked={isChecked('address')}
                onChange={handleChange}
              >
                <Text variant="medium">Address</Text>
              </Checkbox>
            </FormControl>
          </li>
        </ul>
      </div>

      <div className={styles.details}>
        <Heading type="h5" component="h4" variant="neutral">
          Additional data
        </Heading>
        {additionalFields.fields.map((field, index) => (
          <EditableField
            key={field.id}
            index={index}
            name="identification.additionalFields"
            onDelete={() => {
              const { id } = getValues(
                `identification.additionalFields.${index}`
              );

              if (id && hasExtractedData) {
                setModal(
                  <ConfirmationDialog
                    onConfirm={() => additionalFields.remove(index)}
                    onClose={() => setModal(null)}
                  />
                );
              } else {
                additionalFields.remove(index);
              }
            }}
            validate={(value, existingFields) => {
              return validate(
                value,
                existingFields,
                RESERVED_FIELD_NAMES,
                NO_EQUIVALENT_VALUE,
                MAX_LENGTH
              );
            }}
          />
        ))}
        <Link
          onClick={() => additionalFields.append({ name: '' })}
          variant="primary"
          className={styles.AddButton}
        >
          <Icon icon={IconList.light.faPlus} /> Add additional data
        </Link>
      </div>
    </section>
  );
};

export default memo(IdentificationPanel);
