import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import styles from './Modal.module.scss';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Link,
  Button,
} from 'components/core';
import { DataExtractionTemplate as DataExtractionTemplateType } from 'types/DataExtraction';

export function showUnchangedDefaultWarning(
  form: UseFormReturn<DataExtractionTemplateType, any, undefined>
) {
  const { getValues, getFieldState } = form;

  const newTemplate = !getValues('updated_by');

  const defaultsHaveChanged = () => {
    // Currently we only care if the user has change the default
    // interventions or outcomes so we only check these.
    const interventionsDirty =
      getValues('interventions.interventions').length !== 2 ||
      getFieldState('interventions.interventions.0.name').isDirty ||
      getFieldState('interventions.interventions.1.name').isDirty;

    const outcomesDirty =
      getValues('outcomes.outcomes').length !== 2 ||
      getFieldState('outcomes.outcomes.0.name').isDirty ||
      getFieldState('outcomes.outcomes.1.name').isDirty;

    return interventionsDirty || outcomesDirty;
  };

  if (newTemplate) {
    return !defaultsHaveChanged();
  } else {
    return false;
  }
}

interface Props {
  onClose: () => void;
  onPublish: (revisit?: boolean) => void;
}

export const UnchangedDefaultsWarningModal = ({
  onClose,
  onPublish,
}: Props) => {
  const [isOpen, setOpen] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
    >
      <ModalHeader>Are you sure you want to publish?</ModalHeader>
      <ModalBody>
        <Text className={styles.ModalBodyText}>
          You haven&apos;t made many changes to the default template.
        </Text>
        <Text className={styles.ModalBodyText}>
          <strong>
            We strongly recommend that you edit the default list of
            interventions and outcomes
          </strong>{' '}
          so that you can collect data that&apos;s relevant for your review.
        </Text>
        <Text>
          Learn how to set up your template using this{' '}
          <Link
            to={
              'https://support.covidence.org/help/how-to-create-a-data-extraction-template-in-extraction-1'
            }
            target="_blank"
            variant="primary"
          >
            step-by-step guide
          </Link>
          .
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button
          type="neutral"
          variant="outline"
          onClick={() => {
            onClose();
            onPublish();
          }}
        >
          Publish template
        </Button>
        <Button type="brand" onClick={onClose}>
          Continue editing
        </Button>
      </ModalFooter>
    </Modal>
  );
};
